<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">BECOME A PARTNER</div>
    <div class="form">
      <div class="form_title">BECOME A PARTNER</div>
      <div class="form_title2">Become our most reliable partne</div>
      <div class="form_formName">Submit An On-Line Request</div>
      <div class="form_main">
        <input
          type="text"
          placeholder="First Name *"
          v-model="from.firstName"
        />
        <input type="text" placeholder="Last Name *" v-model="from.lastName" />
        <input type="text" placeholder="Company *" v-model="from.company" />
        <input type="text" placeholder="eMail *" v-model="from.email" />
        <input type="text" placeholder="Phone" v-model="from.phone" />
        <!-- 第一个选择框的内容 -->
        <div>
          <el-select
            v-model="checkedCountry"
            filterable
			 no-match-text="No data"
            placeholder="Country *"
            class="select select2"
          >
            <el-option
              v-for="item in countryList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <img src="@/assets/index/popUp.png" alt="" />
        </div>
      </div>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Request *"
        v-model="from.content"
      ></textarea>
      <div class="form_submit" @click="submit">SEND</div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
import { Loading } from "element-ui";
import countryList from "@/utils/country";
export default {
  created() {
    this.countryList = countryList;
  },
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "/contactus", title: "CONTACT US" },
      { path: "", title: "BECOME A PARTNER" },
    ];
  },
  data() {
    return {
      breadcrumbList: [],
      from: {
        company: "",
        content: "",
        country: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
      submitFlag: true,
      countryList: [],
      checkedCountry: "",
    };
  },
  methods: {
    //Country的下拉搜索框
    querySearch(queryString, cb) {
      var restaurants = this.countryList;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //提交
    submit() {
      let loadingInstance = Loading.service(document.body);
      let that = this;
      if (!this.submitFlag) {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        return false;
      } else {
        this.submitFlag = false;
      }
      if (this.checkedCountry != "Country *") {
        this.from.country = this.checkedCountry;
      } else {
        this.$message.warning("Please select a country！");
        this.submitFlag = true;
        this.$nextTick(() => {
          loadingInstance.close();
        });
        return false;
      }
      api.savePartnerApply(this.from).then((res) => {
        if (res.code == 200) {
          this.$message.success("Successful application！");
          this.from = {
            company: "",
            content: "",
            country: "",
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
          };
          setTimeout(() => {
            that.submitFlag = true;
          }, 2000);
          this.$nextTick(() => {
            loadingInstance.close();
          });
        } else {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          that.submitFlag = true;
          this.$message.error(res.msg);
        }
      });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.form {
  width: 1156px;
  margin: 0 auto;

  font-family: Arial;
  font-weight: normal;
  .form_title {
    font-size: 36px;
    color: #004a9c;
    text-align: center;
    padding: 44px 0 20px;
  }
  .form_title2 {
    font-size: 16px;
    color: #5a5b5e;
    text-align: center;
    margin-bottom: 46px;
  }
  .form_formName {
    font-size: 24px;
    color: #5a5b5e;
    text-align: center;
  }
  .form_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
    input {
      width: 324px;
      height: 45px;
      background: #ffffff;
      border: 1px solid #004a9c;
      margin-bottom: 18px;
      font-size: 16px;
      color: #172f66;
      padding: 0 18px;
    }
    input:focus {
      outline: 0;
    }
    .select {
      width: 324px;
      height: 45px;
      background: #ffffff;
      border: 1px solid #004a9c;
      margin-bottom: 18px;
      position: relative;

      font-size: 16px;
      font-family: Arial;
      font-weight: normal;
      color: #172f66;

      padding: 0 19px 0 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select2 {
      width: 360px;
      padding: 0;
    }
    > div {
      position: relative;
      img {
        position: absolute;
        right: 21px;
        top: 20px;
        width: 12px;
        height: 6px;
      }
    }
  }

  textarea {
    width: 1127px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #004a9c;
    padding: 14px;

    font-size: 16px;
    font-family: Arial;
    font-weight: normal;
    color: #172f66;
  }
  textarea:focus {
    outline: 0;
  }
  .form_submit {
    width: 263px;
    height: 45px;
    background: #004a9c;
    font-size: 18px;
    text-align: center;
    line-height: 45px;
    color: #ffffff;
    cursor: pointer;
    margin: 43px auto 53px;
  }
}
</style>


<style scoped>
div /deep/ .el-input__inner {
  height: 45px;
  border: 0;
}
div /deep/ .el-input__suffix-inner {
  display: none;
}
</style>